import React from 'react';

import Logo from '../Logo/Logo'
import classes from './Footer.module.css';


const footer = ( props: any ) => (
    <footer className={classes.Footer}>
        <div >
            &copy;2004-2018 TheCountryStore.com
        </div>
    </footer>
);

export default footer;
