import React from 'react';

import productImage from '../../assets/images/thecountrystore-logo-leaf.jpg';
import classes from './Product.module.css';

const product = (props: any) => (
    <div className={classes.Product} style={{height: props.height}}>
        <div className={classes.ProductPanel}>
            <img src={productImage} alt="TheCountryStore" />
            <p>All things Country Store.</p>
        </div>
    </div>
);

export default product;
